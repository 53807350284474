import request from "../utils/request";

export const initHome = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "lookwork/find",
        method: "get",
        params: query,
    });
};



